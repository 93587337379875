import React, { useState, useEffect } from "react";
import BodyClassName from "react-body-classname";

const BodyClass = () => {
  let [bodyClasses, setBodyClassName] = useState("is-preload");

  useEffect(() => {
    let resizeTimeout = null;
    setBodyClassName("");
    window.addEventListener(
      "resize",
      () => {
        setBodyClassName("is-resizing");
        clearTimeout(resizeTimeout);
        resizeTimeout = setTimeout(() => {
          setBodyClassName("");
        }, 100);
      },
      []
    );
    return () =>
      window.removeEventListener("resize", () => {
        setBodyClassName("is-resizing");
        clearTimeout(resizeTimeout);
        resizeTimeout = setTimeout(() => {
          setBodyClassName("");
        }, 100);
      });
  }, []);
  return <BodyClassName className={bodyClasses} />;
};

export default BodyClass;
