import { useStaticQuery, graphql } from "gatsby";

const useCategories = () => {
  const data = useStaticQuery(graphql`
    query {
      categories: allFile(
        filter: { sourceInstanceName: { eq: "categories" } }
      ) {
        nodes {
          category: childMdx {
            fields {
              slug
            }
            frontmatter {
              level
              division
            }
          }
        }
      }
    }
  `);

  const categories = data.categories.nodes;

  const items = [];
  return categories
    .map(({ category }, index, array) => {
      if (items.includes(category.frontmatter.level)) return undefined;
      items.push(category.frontmatter.level);
      return {
        level: category.frontmatter.level,
        divisions: array
          .map(({ category: cat }) => {
            if (cat.frontmatter.level === category.frontmatter.level) {
              return {
                division: cat.frontmatter.division,
                slug: cat.fields.slug,
              };
            }
            return undefined;
          })
          .filter(v => v !== undefined),
      };
    })
    .filter(v => v !== undefined);
};

export default useCategories;
