import React, { useState } from "react";
import { Link, navigate } from "gatsby";

import Sidebar from "./Sidebar";
import { ls } from "utility/helper";

const items = {
  courses: false,
  exercises: false,
  status: false,
  private: false,
};

const ItemIcon = ({ name = "", text = "" }) => {
  return (
    <div style={{ textAlign: "center" }}>
      <i
        style={{ fontSize: "1.5em", padding: 0, margin: 0 }}
        className={`icon icon-${name}`}
      />
      <p style={{ margin: 0, padding: 0 }}>{text}</p>
    </div>
  );
};
const Menu = ({ location, isActive, setIsActive }) => {
  const [menuItem, setMenuItems] = useState(items);
  const [tab, setTab] = useState("courses");

  const { isLoggedIn } = ls("profile") ? ls("profile") : { isLoggedIn: false };

  const handleClick = ({ item = "", to = null }) => () => {
    setMenuItems({ ...items, [item]: true });

    if (to) {
      navigate(to);
    } else {
      setTab(item);
      setIsActive(true);
    }
  };
  return (
    <div>
      <Sidebar
        isActive={isActive}
        setIsActive={setIsActive}
        tab={tab}
        location={location}
      />
      <div className="fixed">
        <ul>
          <li
            className={menuItem.status ? "active" : ""}
            onClick={handleClick({
              item: "status",
              to: isLoggedIn ? "/App/dashboard" : "/Signin",
            })}
          >
            {isLoggedIn ? (
              <ItemIcon name="meter" text="لوحة التحكم" />
            ) : (
              <ItemIcon name="login" text="تسجيل" />
            )}
          </li>
          <li
            className={menuItem.courses ? "active" : ""}
            onClick={handleClick({ item: "courses" })}
          >
            <ItemIcon name="file-text" text="دروس" />
          </li>
          <li
            className={menuItem.exercises ? "active" : ""}
            onClick={handleClick({ item: "exercises" })}
          >
            <ItemIcon name="calculator" text="تمارين" />
          </li>

          <li
            className={menuItem.private ? "active" : ""}
            onClick={handleClick({ item: "private", to: "/دروس-خصوصية" })}
          >
            <ItemIcon name="pen" text="دروس خصوصية" />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Menu;
