import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

const query = graphql`
  query {
    file(relativePath: { eq: "logo.png" }) {
      logo: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`;
const Logo = ({ style = {} }) => {
  const {
    file: { logo },
  } = useStaticQuery(query);
  return (
    <span className="image image-logo" style={style}>
      <Img fluid={logo.fluid} />
    </span>
  );
};

export default Logo;
