import React, { useState } from "react";
import { Link, navigate } from "gatsby";

import useCategories from "../../hooks/useCategories";

const menuItemsInit = count => {
  const states = [];
  for (let c = 0; c < count; c++) {
    states.push(false);
  }
  return states;
};

const MenuDetails = ({ tab, category = "", setIsActive }) => {
  const menuItems = useCategories();
  let [isOpen, setIsOpen] = useState(menuItemsInit(menuItems.length));

  const toggleOpner = (menuNumber, items) => event => {
    event.preventDefault();
    setIsOpen(isOpen.map((v, index) => (menuNumber === index ? !v : false)));
    if (items.divisions.length === 1) {
      navigate(`${items.divisions[0].slug}#${tab}`);
    }
  };

  const handleClick = () => setIsActive(false);
  const translation = { exercises: "تمارين", courses: "دروس" };
  return (
    <nav id="menu">
      <header className="major">
        <h2>{translation[tab]}</h2>
      </header>
      <ul>
        {menuItems.map((items, index) => {
          return (
            <li key={index}>
              <span
                className={`opener ${isOpen[index] ? "active" : ""}`}
                onClick={toggleOpner(index, items)}
              >
                {items.level}
              </span>
              <ul>
                {items.divisions.map((item, index) => {
                  return (
                    <li onClick={handleClick} key={index}>
                      <Link to={`${item.slug}#${tab}`}>{item.division}</Link>
                    </li>
                  );
                })}
              </ul>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default MenuDetails;
