import React from "react";
import { useEffect, useContext } from "react";
import { Link } from "gatsby";
import MenuDetails from "./MenuDetails";
import { InitModulesContext } from "../../hooks/useContext";

// import breackpoints from "../js/breakpoints";

const Sidebar = ({ isActive, setIsActive, tab, location }) => {
  const { breackpoints } = useContext(InitModulesContext);

  const preventPropagation = event => {
    event.stopPropagation();
    if (breackpoints.active(">large")) return;
  };
  return (
    <div
      id="sidebar"
      className={isActive ? "active" : "inactive"}
      onClick={preventPropagation}
      onTouchEnd={preventPropagation}
      onTouchStart={preventPropagation}
      onTouchMove={preventPropagation}
    >
      <div className="inner">
        {/* TODO: create search feat */}
        <MenuDetails tab={tab} setIsActive={setIsActive} />
        <footer id="footer">
          <Link to="/privacyPolicy">سياسة الخصوصية</Link>
          <p className="copyright">
            كل الحقوق محفوظة للأستاذ بن أحمد &copy; {new Date().getFullYear()}
          </p>
        </footer>
      </div>
    </div>
  );
};

export default Sidebar;
